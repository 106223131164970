import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://peneciesmantable.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce";
export const REGISTER_URL = "https://peneciesmantable.com/5797b23e-1351-424d-b873-ae00f8e6ba9d";

export const LOGIN_URL_MAP = {
  "www-wow69.win": "https://a.ntrk2.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce",
  "www-wow69.com": "https://a.ntrk2.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce",
  "www-wow69.co": "https://a.ntrk2.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce",
  "www-wow69play.com": "https://a.ntrk2.com/7a438880-37d3-4462-bc91-f44559740018",
  "wow69wow.com": "https://a.ntrk2.com/7a438880-37d3-4462-bc91-f44559740018",
};

export const REGISTER_URL_MAP = {
  "www-wow69.win": "https://a.ntrk2.com/5797b23e-1351-424d-b873-ae00f8e6ba9d",
  "www-wow69.com": "https://a.ntrk2.com/5797b23e-1351-424d-b873-ae00f8e6ba9d",
  "www-wow69.co": "https://a.ntrk2.com/5797b23e-1351-424d-b873-ae00f8e6ba9d",
  "www-wow69play.com": "https://a.ntrk2.com/0d4857fa-cb98-4864-87a5-d4c143230be2",
  "wow69wow.com": "https://a.ntrk2.com/0d4857fa-cb98-4864-87a5-d4c143230be2",
};

export const FLOATING_BANNER_URL = "https://wow699.com/cs";

export * from "./codes";
export * from "./context";
